<template>
  <ClientOnly>
    <AtroMenu
      align="bottom"
      class="w-full"
      menu-button-class="w-full hover:text-primary focus-visible:text-primary has-[.open]:text-primary"
      menu-item-class="text-primary hover:text-primary-75"
      :is-mobile="$isMobile.value"
      :items="menuItems"
      @action="onAction"
      @navigate-to="navigateTo"
    >
      <template #menuButton="{ open }">
        <UserDisplay
          :user
          :class="open && 'open'"
          :is-row="false"
          :profile-modal-enabled="false"
        >
          <AtroSpan
            v-if="org"
            class="w-full truncate text-start text-atro-gray"
            size="sm"
            :text="org?.name"
          />
        </UserDisplay>
      </template>

      <template #menuItemsPrefix>
        <!-- Profile -->
        <AtroContent
          v-if="org"
          class="cursor-pointer py-2 text-primary hover:text-primary-75"
          items="center"
          wrap="nowrap"
          @click="() => navigateToWithOrg('/manage/profile')"
        >
          <UserAvatar
            :user
            :class="{ 'mr-1': !$isMobile.value, 'mr-1.5': $isMobile.value }"
            :size="$isMobile.value ? 'md' : 'sm'"
          />
          <AtroSpan semibold text="My profile" />
        </AtroContent>
      </template>
    </AtroMenu>
    <template #fallback>
      <UserDisplay
        :user
        class="w-full"
        :is-row="false"
        :profile-modal-enabled="false"
      >
        <AtroSpan
          v-if="org"
          class="w-full truncate text-start text-atro-gray"
          size="sm"
          :text="org?.name"
        />
      </UserDisplay>
    </template>
  </ClientOnly>
</template>

<script setup lang="ts">
export interface Props {
  expanded?: boolean
}

defineProps<Props>()

const { org } = useCurrentOrg()
const { user } = useCurrentUser()
const { menuItems, onAction } = useUserNav()
</script>
